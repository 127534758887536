@import "bootstrap/dist/css/bootstrap.min.css";

body {
    overflow-x: hidden;
}

.carousel-item { 
    visibility: hidden;
    display: flex!important;
    flex-direction: column;
    align-self: center;  
    transition: none;
}

.carousel-inner {
    display: flex;
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
    visibility: visible;
}

.homeStart {
    position: relative;
    padding: 5px 50px;
}

.homeStart .left-col .slide-inner a {
    display: none;
}

.our-advantages svg,
.our-strategy svg,
#our-advantages svg {
    width: 64px;
    height: 64px;
    margin-bottom: 15px;
}

#return-funds .row.return-funds-inner {
    border-top: 2px solid #007bff;
    padding-top: 15px;
    margin-top: 35px;
}

#return-funds .row.return-funds-inner > div:before {
    content: '';
    width: 10px;
    height: 10px;
    background: #fff;
    position: absolute;
    left: calc(50% - 10px);
    top: -26px;
    border-radius: 50%;
}

    #return-funds .row.return-funds-inner {
        border-top: none;
        border-left: 1px solid #fff;
        padding-left: 15px;
        margin-left: 15px;
    }

    #return-funds .row.return-funds-inner > div:before {
        top: calc(50% - 10px);
        left: -21px;
    }
    
#details {
    background: #fff;
    border-radius: 25px;
    border: 1px solid #ececec; 
}

.slider {
    position: relative;
}

.slider .bg-banner-image {
    position: absolute;
    z-index: -2;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.slider h2 {
    color: #b5c2f2;
    text-transform: uppercase;
}

.slider:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #070707e0;
    z-index: -1;
}

.homeStart .right form {
    z-index: 50;
}

.homeStart .right .btn-primary {
     width:100%;
}

.homeStart .right input {
    border-radius: 15px;
}

.PhoneInput {
    border-radius: 15px;
    display: flex!important;
}

.about-img {
    width: 250px;
    position: absolute;
    top: -85px;
    right: 0;
    z-index: -1; 
}

.PhoneInputInput {
    outline: none;
    border: none;
}

.form-group {
    position: relative;
}

.imgCheckPhone {
    width: 20px;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

h3 {
    font-size: 26px;
    color: #353B8B;
    font-weight: 500;
    text-transform: uppercase;
}

h4 {
    font-size: 19px;
    color: #353B8B;
    font-weight: 500;
    text-transform: uppercase;
}

h5 {
    text-transform: uppercase;
}

.btn-primary, .btn-light {
    border-radius: 15px;
    padding: 10px 35px;
}

.homeStart .left-col p {
    font-size: 24px;
    color: #e0ecff;
}

#map-section iframe {
    width: 100%;
}

.slider {

}

.carousel-control-prev {
    left: -90px;
}

.carousel-control-next {
    right: -60px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-color: #000;
    padding: 15px;
    display: block;
    border-radius: 50%;
    background-size: 15px;
    background-position-x: 7.5px;
}

#testimonials .carousel.slide {
    width: 100%; 
    padding: 35px;
}

#testimonials .carousel.slide p {
    font-style: italic;
}

#testimonials .user-avatar img {
    width: 100%;
    border-radius: 50%;
    margin-bottom: 10px;
}

footer .navbar {
    justify-content: space-between;
}

footer .copyright {
    text-align: center;
    width: 100%;
    background: #35508b;
    color: #ffffff80;
    padding: 10px;
}

.bg-dark {
    background: #170a20!important;
}

section.bg-dark {
    color: #fff;
}

section.bg-dark h3 {
    color: #b5c2f2;
}

@media screen and (max-width: 1200px) {
    h5 {
        font-size: 18px;
    }
}

@media screen and (max-width: 768px) {
    .homeStart .left-col .slide-inner a {
        display: block;
    }
    
    .carousel-control-prev {
        left: -60px;
    }

    .carousel-control-next {
        right: -60px;
    }
    
    .homeStart {
        padding: 50px 50px;
    }
    
    h3 {
        font-size: 18px;
        font-weight: 500;
    }
    
    .homeStart .left-col p {
        font-size: 16px;
    }
    
    .our-strategy .col-md-4 {
        order: -1;
    }
    
    .our-strategy .col-md-4 img,
    #our-advantages img {
        max-height: 250px;
        margin-bottom: 35px;
    }
    
    #testimonials .carousel-control-prev {
        left: -10px;
    }
    
    #testimonials .carousel-control-next  {
        right: -10px;
    }
    
    #testimonials .user-avatar img {
        width: 64px;
    }
    
    footer .navbar {
        padding: 20px 10px;
    }
    
    footer .navbar-nav {
        flex-wrap: wrap;
        padding-left: 35px;
    }
    
    footer .navbar-nav > a {
        width: 50%;
    }
}

@media screen and (max-width: 450px) {
    .carousel-control-prev {
        left: -40px;
    }

    .carousel-control-next {
        right: -40px;
    }
    
    #testimonials h5 {
        font-size: 11px;
    }
    
    footer .navbar-nav > a {
        width: 100%;
    }    
}