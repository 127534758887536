.whatsapp-icon, .tg-icon {
  position: fixed;
  bottom: 80px;
  left: 50px;
  width: 60px;
  height: 60px;
  border-radius: 7px;
  background-color: #27a737;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 50000;
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;  
}

.whatsapp-icon:hover, .tg-icon:hover { 
    background-color: #27a737b3;
    animation-name: unset;
    opacity: 0.9;
}

.whatsapp-icon svg, .tg-icon svg {
  width: 42px;
  height: 42px;
}

.tg-icon svg {
  width: 100%;
  height: 100%;
  fill: #fff;
  border-radius: 5px;
}

.tg-icon {
    bottom: 150px;
    background: #03a9f4;
}

.tg-icon:hover {
    background: #03a9f4;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg)scale(1);
  }
  50% {
    transform: rotate(-22deg)scale(1.15);
  }
  100% {
    transform: rotate(0deg)scale(1);
  }
}